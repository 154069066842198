<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <!--<a-form-model-item label="报名用户id" prop="userId" >-->
        <!--<a-input v-model="form.userId" placeholder="请输入报名用户id" />-->
      <!--</a-form-model-item>-->
      <a-form-model-item label="学员姓名" prop="name" >
        <a-input v-model="form.name" placeholder="请输入学员姓名" />
      </a-form-model-item>
      <a-form-model-item label="身份证号" prop="idCard" >
        <a-input v-model="form.idCard" placeholder="请输入身份证号"/>
      </a-form-model-item>
      <a-form-model-item label="性别" prop="sex" >
        <a-select placeholder="请选择性别" v-model="form.sex" >
          <a-select-option v-for="(d, index) in sexOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="本地外地" prop="nonlocal" >
        <a-select placeholder="请选择性别" v-model="form.nonlocal" >
          <a-select-option v-for="(d, index) in typeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>

<!--        <a-input v-model="form.nonlocal" placeholder="请输入1本地 0外地" />-->
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="mobile" >
        <a-input v-model="form.mobile" placeholder="请输入联系电话" />
      </a-form-model-item>




      <a-form-model-item label="校区" prop="campusId" >
        <a-select placeholder="请选择" v-model="form.campusId" >
                    <a-select-option v-for="(d, index) in campusOptions" :key="index" :value="d.id" >{{ d.name }}</a-select-option>
                  </a-select>

<!--        <a-input v-model="form.campusName" placeholder="请输入校区" />-->
      </a-form-model-item>





      <a-form-model-item label="班别" prop="classType" >
        <a-select placeholder="请选择班别" v-model="form.classType">
          <a-select-option v-for="(d, index) in classTypeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>

<!--      <a-form-model-item label="会员类型" prop="vipType" >-->
<!--        <a-select placeholder="请选择会员类型" v-model="form.vipType" >-->
<!--          <a-select-option v-for="(d, index) in vipTypeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="报名项目" prop="trainingId" >-->
<!--        <a-select placeholder="请选择" v-model="form.trainingId" :disabled='rewardUserList.length>0'>-->
<!--          <a-select-option v-for="(d, index) in trainingOptions" :key="index" :value="d.id" >{{ d.name }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="项目id" prop="trainingId" >-->
<!--      <a-input v-model="form.trainingId" placeholder="请输入项目id" disabled="true"/>-->
<!--      </a-form-model-item>-->



      <a-form-model-item label="车型" prop="carType" >
        <a-select placeholder="请选择" v-model="form.carType">
          <a-select-option v-for="(d, index) in trainingCourseOptions" :key="index" :value="d.label" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item label="服务员工" prop="coachId" >-->
<!--        <a-select placeholder="请选择" v-model="form.coachId">-->
<!--          <a-select-option v-for="(d, index) in coachOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item label="缴纳金额" prop="payMoney" >-->
<!--        <a-input type="number" v-model="form.payMoney" placeholder="请输入缴纳金额" />-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item label="支付状态" prop="payState" >-->
<!--        <a-select placeholder="请选择" v-model="form.payState" :disabled='show'>-->
<!--          <a-select-option v-for="(d, index) in payStateOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item label="是否关联邀请人项目" prop="recommend" v-if="form.referrerStatus==2">-->
<!--        <a-radio-group v-model="form.isCorrelation" button-style="solid">-->
<!--          <a-radio-button v-for="(d, index) in  typeOptions" :key="index" :value="d.value">-->
<!--            {{ d.label }}-->
<!--          </a-radio-button>-->
<!--        </a-radio-group>-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item label="邀请人车型" prop="inviteOrderTraining" v-if="form.referrerMobile&&(form.referrerStatus==1||form.isCorrelation==1)">-->
<!--        <a-select placeholder="请选择" v-model="form.inviteOrderTraining">-->
<!--          <a-select-option v-for="(d, index) in inviteOrderList" :key="index" :value="d.id" >{{ d.carType }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getOrder, addOrder, updateOrder, getCampus,getTraining} from '@/api/driving-school/order'

export default {
  name: 'CreateAddForm',
  props: {
    trainingCourseOptions: {
      type: Array,
      required: true
    },
    sexOptions: {
      type: Array,
      required: true
    },
    vipTypeOptions: {
      type: Array,
      required: true
    },
    classTypeOptions: {
      type: Array,
      required: true
    },
    payStateOptions: {
      type: Array,
      required: true
    },
    isInvoiceOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      typeOptions: [{"value":"1","label":"本地"},{"value":"0","label":"外地"}],
      show: false,
      submitLoading: false,
      formTitle: '',
      TrainingCourseOptions: [],
      // coachOptions: [],
      inviteOrderList: [],
      rewardUserList: [],
      campusOptions: [],
      // 表单参数
      form: {
        id: null,

        userId: null,

        isCorrelation: null,
        payState:null,

        name: null,
        coachId:null,

        inviteSum:null,

        idCard: null,

        sex: null,

        trainingId: null,
        course: null,

        vipType: null,

        inviteOrderTraining: null,

        classType: null,
        carType: null,
        campusId: null,

        nonlocal: null,

        mobile: null,

        referrerName: null,

        referrerMobile: null,

        areaName: null,

        campusName: null,

        isInvoice: null,

        payMoney: null,

        leaveWords: null,

        createTime: null,

        empName: null,
        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [{ required: true, message: '请填写学员姓名', trigger: 'blur' }],
        idCard: [{ required: true, message: '请填写身份证', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        nonlocal: [{ required: true, message: '请选择', trigger: 'blur' }],
        mobile: [{ required: true, message: '请填写手机', trigger: 'blur' }],
        campusId: [{ required: true, message: '请选择校区', trigger: 'blur' }],
        classType: [{ required: true, message: '请选择班别', trigger: 'blur' }],
        carType: [{ required: true, message: '请选择车型', trigger: 'blur' }],

      }
    }
  },
  filters: {
  },
  created () {
    // this.getTrainingOptions()
    this.getCampusOptions()
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    getCampusOptions() {
      getCampus().then(res=> {
        this.campusOptions = res.data
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        name: null,
        idCard: null,
        sex: null,
        trainingId: null,
        vipType: null,
        classType: null,
        carType: null,
        campusId: null,
        nonlocal: null,
        inviteSum: null,
        mobile: null,
        referrerName: null,
        referrerMobile: null,
        areaName: null,
        campusName: null,
        isInvoice: null,
        payMoney: null,
        isCorrelation: null,
        leaveWords: null,
        coachId: null,
        createTime: null,
        empName: null,
        course: null,
        remark: null,
        inviteOrderTraining: null,
        payState: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },

    // getTrainingCourse(value, option){
    //   getTrainingCourse({"id":value}).then(response => {
    //     this.TrainingCourseOptions = response.data
    //   })
    // },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateOrder(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addOrder(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
